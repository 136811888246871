<template>
  <div class='houseInfoForm-container'>
    <form-panel :hasHeader="false" :submitUrl="submitUrl" :form="form" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <template #headerSlot>
        <v-button text="返回"  @click="returnClick" />
      </template>
      <div>
        <el-form-item v-if="isShowCreate" class="communityId" label="项目信息" prop="communityId" :rules="[{ required: true, message: '请选择项目信息', trigger: 'change' }]" >
          <v-select2 v-model="form.communityId" placeholder="查询项目" v-bind="communityIdConfig"></v-select2>
        </el-form-item>
        <el-form-item v-if="isShowEdit"  class="communityId" label="项目信息" prop="communityId" :rules="[{ required: true, message: '请选择项目信息', trigger: 'change' }]">
          <v-select v-model="form.communityId" :options="communityIdOps"  @change="changeCommunityInfo"></v-select>
        </el-form-item>
        <span class="addHouse"  @click="addHouse">增加房产</span>
      </div>
      <div v-if="isShowEdit && isShowOrg">
        <span class="orgId">*</span>
        <el-form-item label="组织信息">
          <v-select v-model="orgId" :options="orgIdOps"></v-select>
          <v-button type="danger" style="margin-left: 38px;" @click="removeOrg">取消关联</v-button>
        </el-form-item>
      </div>
      <el-row type="flex">
        <el-form-item v-if="isShowCreate"  label="房产信息">
          <v-select2 v-model="form.roomId" placeholder="查询房产" v-bind="roomIdConfig" :subjoin="communityIdParams" @onChange="getHouseType"></v-select2>
        </el-form-item>
        <el-form-item v-if="isShowEdit" label="房产信息" prop="roomId" :rules="[{ required: true, message: '请选择房产信息', trigger: 'change' }]">
          <v-select v-model="form.roomId" :options="roomIdOps" @change="changeRoomInfo"></v-select>
        </el-form-item>

        <el-form-item label="房产类型" prop="houseType">
          <v-select v-model="form.houseType" disabled :options="houseTypeOps"></v-select>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="所属组团" prop="groupTeam">
          <v-input v-model="form.groupTeam"></v-input>
        </el-form-item>
        <el-form-item label="房号编号" prop="roomNumber">
          <v-input v-model="form.roomNumber"></v-input>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="完整编号" prop="roomFullNumber">
          <v-input v-model="form.roomFullNumber"></v-input>
        </el-form-item>
        <el-form-item label="身份" prop="userType" :rules="[{ required: true, message: '请选择身份', trigger: 'change' }]">
          <v-select v-model="form.userType" :options="userTypeOps" @change="changeUserType"></v-select>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="人房关系" prop="personRoomRelationship">
          <v-select v-model="form.personRoomRelationship" :options="personRoomList"></v-select>
        </el-form-item>
        <el-form-item label="租住关系" prop="lesseeRelationship">
          <v-select v-model="form.lesseeRelationship" :options="lesseeRelationshipList"></v-select>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="房东姓名" prop="houseOwnerName">
          <v-input v-model="form.houseOwnerName"></v-input>
        </el-form-item>
        <el-form-item label="房东身份证号码" prop="houseOwnerCardnum">
          <v-input v-model="form.houseOwnerCardnum"></v-input>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="房东手机号" prop="houseOwnerMobilenum">
          <v-input v-model="form.houseOwnerMobilenum"></v-input>
        </el-form-item>
        <el-form-item label="业主状态" prop="ownerState">
          <v-input v-model="form.ownerState"></v-input>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="买房日期" prop="purchaseDate">
          <v-datepicker :width="width" type="date" v-model="form.purchaseDate"/>
        </el-form-item>
        <el-form-item label="退租日期" prop="rentWithdrawalDate">
          <v-datepicker :width="width" type="date" v-model="form.rentWithdrawalDate"/>
        </el-form-item>
      </el-row>
      <el-form-item label="租住时间">
          <v-datepicker :startTime.sync="form.rentStartDate" :endTime.sync="form.rentEndDate"/>
        </el-form-item>
      <el-row type="flex">
        <el-form-item label="人员类型" prop="personType">
          <v-select v-model="form.personType" :options="personTypeList"></v-select>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item label="智能开门" prop="isOpenDoor">
          <v-select class="isExamine" v-model="form.isOpenDoor" :options="isOpenDoorOps"></v-select>
          <span>注：仅控制蓝牙权限</span>
        </el-form-item>
        <el-form-item v-if="$store.getters.getPermission('isLadderControl')" label="梯控权限" prop="isOpenDoor">
          <v-checkbox label="二维码" v-model="form.isLadderControl" />
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <el-form-item v-if="isShowEdit" label="是否当前住址" prop="isDefault">
          <v-select v-model="form.isDefault" disabled :options="isDefaultOps"></v-select>
        </el-form-item>
      </el-row>
      <el-row type="flex">
        <div v-if="isShowHouseHolder">
          <el-form-item label="是否户主" prop="isHouseHolder">
            <checkbox-plus style="float: left;" type="radio" :options="isHouseHolderOps" :value.sync="isHouseHolder" ></checkbox-plus>
            <span style="margin-left: 20px;">当前户主：&nbsp;&nbsp;{{houseHolderName}}&nbsp;&nbsp;</span>
            <span style="color: red;margin-left: 20px;">注意</span><span>:&nbsp;&nbsp;户主唯一，且身份必须为业主</span>
          </el-form-item>
        </div>
      </el-row>
      <div v-if="orgTag === 2 || userTypeOps.length > 3">
        <el-row type="flex">
          <el-form-item label="与户主关系" prop="userRelation">
            <v-select v-model="form.userRelation" :options="userRelationOps"></v-select>
          </el-form-item>
          <el-form-item label="是否付款联系人" prop="isPayment" :rules="[{ required: true, message: '请选择付款联系人', trigger: 'change' }]">
            <v-select v-model="form.isPayment" :options="isPaymentOps"></v-select>
          </el-form-item>
        </el-row>
        <el-form-item label="是否短信联系人" prop="isMessage" :rules="[{ required: true, message: '请选择短信联系人', trigger: 'change' }]">
          <v-select v-model="form.isMessage" :options="isMessageOps"></v-select>
        </el-form-item>
      </div>
      <!-- <div v-if="isShowEdit && isShowOrg">
        <span class="orgId">*</span>
        <el-form-item label="组织信息">
          <v-select v-model="orgId" :options="orgIdOps"></v-select>
        </el-form-item>
      </div> -->

      <div v-if="orgTag === 2 || (isShowEdit && userTypeOps.length > 3)">
        <el-form-item label="是否业委会成员">
          <span>{{isCommit}}</span>
        </el-form-item>
        <el-form-item label="业委会职位">
          <span>{{commitDuty}}</span>
        </el-form-item>
      </div>
      <div v-if="orgTag === 1">
        <el-form-item label="是否超管">
          <span>{{isSuperManager}}</span>
          <span class="isSuperManager">当前超管：&nbsp;&nbsp;{{houseHolderName}}&nbsp;&nbsp;</span>
          <span style="color: red;">注意</span><span>:&nbsp;&nbsp;超管唯一</span>
        </el-form-item>
        <el-form-item label="是否为成员管理员">
          <span>{{isMemberManager}}</span>
        </el-form-item>
        <el-form-item label="是否对外代表">
          <span>{{isOutsideRepresentative}}</span>
        </el-form-item>
      </div>
      <div v-show="!isAudio">
        <el-form-item v-if="isShowEdit" label="审核状态" prop="isExamine" :rules="[{ required: true, message: '请选择审核状态', trigger: 'change' }]">
          <v-select class="isExamine" v-model="form.isExamine" :options="isExamineOps"></v-select>
          <span>注：已通过用户即为项目住户，未通过则不是</span>
        </el-form-item>
        <el-form-item v-show="form.isExamine === 2 && isShowEdit" label="审核原因" :rules="[{ required: true }]">
          <v-textarea v-model="form.rejectReason"></v-textarea>
        </el-form-item>
        <el-form-item v-show="form.isExamine !== 2 && isShowEdit" label="审核原因" prop="rejectReason">
          <v-textarea v-model="form.rejectReason"></v-textarea>
        </el-form-item>
      </div>
    </form-panel>
  </div>
</template>
<script>
import * as allOwnerMgrFormUrl from './api'
import * as commonData from './map'
import { CheckboxPlus } from 'components/bussiness/index'
import { normalCommunityParams } from 'common/select2Params'
import { Row} from 'element-ui'
export default {
  name: 'newHouseInfoForm',
  components: {
    CheckboxPlus,
    elRow: Row,
  },
  props: ['ownerAssets', 'userId', 'isAudio','query'],
  data () {
    return {
      width: 144,
      personRoomList: [],
      lesseeRelationshipList: [],
      personTypeList: [],
      form: {
        communityId: undefined,
        roomId: undefined,
        houseType: undefined,
        rentStartDate: '',
        rentEndDate: '',
        personRoomRelationship: undefined,
        lesseeRelationship: undefined,
        personType: undefined,
        houseOwnerName: '',
        houseOwnerCardnum: '',
        houseOwnerMobilenum: '',
        userType: undefined,
        userRelation: undefined,
        isPayment: undefined,
        isMessage: undefined,
        isOpenDoor: undefined,
        isDefault: undefined,
        isExamine: undefined,
        rejectReason: '',
        groupTeam: '',
        roomNumber: '',
        roomFullNumber: '',
        ownerState: '',
        rentWithdrawalDate: '',
        purchaseDate: '',
        isLadderControl: false,   // 梯控权限 0 无权限 1有权限
      },
      isHouseHolder: '',
      isShowCreate: false,
      isShowEdit: false,
      isExamine: '',
      orgId: undefined,
      isEditShow: false,
      communityIdOps: [],
      orgIdOps: [],
      roomInfo: '',
      roomIdOps: [],
      isCommit: '',
      commitDuty: '',
      orgTag: undefined,
      houseHolderName: '暂无...',
      isSuperManager: '',
      isMemberManager: '',
      isOutsideRepresentative: '',
      isShowHouseHolder: false,
      communityIdConfig: normalCommunityParams,
      roomIdConfig: {
        searchUrl: allOwnerMgrFormUrl.select2RoomIdUrl,
        request: {
          text: 'address',
          value: 'id'
        },
        response: {

        }
      },
      isHouseHolderOps: commonData.isHouseHolderOps,
      houseTypeOps: commonData.houseTypeOps,
      // userTypeOps: commonData.setUserTypeOps(2),
      userTypeOps: commonData.userTypeOps,
      userRelationOps: commonData.userRelationOps,
      isPaymentOps: commonData.isPaymentOps,
      isMessageOps: commonData.isMessageOps,
      isOpenDoorOps: commonData.isOpenDoorOps,
      isDefaultOps: commonData.isDefaultOps,
      isExamineOps: commonData.isExamineOps,
      submitUrl: allOwnerMgrFormUrl.createHouseInfoUrl,
      isShowOrg: false,
      assetId: ''
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
    communityIdParams () {
      return {
        communityId: this.form.communityId
      }
    },
    type () {
      return {
        type: this.queryReal.type
      }
    }
  },
  created () {
    if (this.queryReal.id) {
      this.submitUrl = allOwnerMgrFormUrl.updateHouseInfoUrl
      this.isShowCreate = false
      this.isShowEdit = true
    } else {
      this.isShowCreate = true
      this.isShowEdit = false
    }
  },
  mounted () {
    this.getNationData()
    let { id, addUserId } = this.queryReal
    if ((id || addUserId) && (this.ownerAssets.data.length > 0 || (this.ownerAssets.data.communityAssetVS && this.ownerAssets.data.communityAssetVS.length > 0))) {
      this.editGetCommunityInfo()
      let type = Number(this.queryReal.type)
      if (type === 0 && this.form.userType === 1) {
        this.isShowHouseHolder = true
      } else if (type === 1 && this.form.userType === 1 && this.userTypeOps.length > 3) {
        this.isShowHouseHolder = true
      } else {
        this.isShowHouseHolder = false
      }
    }
  },
  updated () {
    this.isExamine = this.form.isExamine
  },
  methods: {
    // 获取民族一系列列表
    getNationData () {
      let that = this
      this.$axios.get(allOwnerMgrFormUrl.getInitCodeWordList)
        .then(res => {
          res.data.personRoomList.forEach(function (item) {
            that.personRoomList.push({
              text: item,
              value: item
            })
          })
          res.data.lesseeRelationshipList.forEach(function (item) {
            that.lesseeRelationshipList.push({
              text: item,
              value: item
            })
          })
          res.data.personTypeList.forEach(function (item) {
            that.personTypeList.push({
              text: item,
              value: item
            })
          })
        })
    },
    returnClick () {
      let type = Number(this.type.type)
      if (type === 0) {
        this.$router.push({
          name: 'serverOwnerAction!getAllOwnerList.action2'
        })
      } else {
        this.$router.push({
          name: 'serverOwnerAction!getAllOwnerList.action1'
        })
      }
    },
    // 回显赋值
    editGetCommunityInfo () {
      const _this = this
      const type = Number(_this.queryReal.type)
      let communityAssetVS
      if (type === 1) {
        _this.isShowOrg = true
        _this.orgIdOps.push({
          text: _this.ownerAssets.data.orgName,
          value: _this.ownerAssets.data.orgTag
        })
        _this.orgId = _this.orgIdOps[0].value
        _this.orgTag = Number(_this.orgId)
        if (_this.orgTag === 1) {
          _this.userTypeOps = commonData.businessUserTypeOps
        }
        communityAssetVS = _this.ownerAssets.data.communityAssetVS
      } else {
        console.log(_this.ownerAssets.data[0].assets[0])
        _this.orgIdOps.push({
          text: _this.ownerAssets.data[0].assets[0].orgName == '' ? '无' : _this.ownerAssets.data[0].assets[0].orgName,
          value: _this.ownerAssets.data[0].assets[0].orgId
        })
        _this.orgId = _this.orgIdOps[0].value
        _this.isShowOrg = false
        communityAssetVS = _this.ownerAssets.data
      }
      // 获取项目信息
      communityAssetVS.map(item => {
        _this.communityIdOps.push({
          text: item.communityName,
          value: item.communityId
        })
      })
      if (_this.queryReal.communityId) {
        _this.form.communityId = _this.queryReal.communityId
      } else if (_this.communityIdOps.length > 0) {
        _this.form.communityId = _this.communityIdOps[0].value
      }
      // 根据项目id回显房产信息
      this.editGetassets(_this.form.communityId)
      if (type === 1) {
        let data = _this.ownerAssets.data.communityAssetVS[0].assets[0]
        this.form.rentStartDate = data.rentStartTime
        this.form.rentEndDate = data.rentEndTime
        this.form.personRoomRelationship = data.personRoomRelationship === '' ? undefined : data.personRoomRelationship
        this.form.lesseeRelationship = data.lesseeRelationship === '' ? undefined : data.lesseeRelationship
        this.form.houseOwnerName = data.houseOwnerName
        this.form.houseOwnerCardnum = data.houseOwnerCardnum
        this.form.houseOwnerMobilenum = data.houseOwnerMobilenum
        this.form.personType = data.personType === '' ? undefined : data.personType
        this.form.groupTeam = data.groupTeam
        this.form.roomNumber = data.roomNumber
        this.form.roomFullNumber = data.roomFullNumber
        this.form.ownerState = data.ownerState
        this.form.rentWithdrawalDate = data.rentWithdrawalDate
        this.form.purchaseDate = data.purchaseDate
      } else {
        let data = _this.ownerAssets.data[0].assets[0]
        this.form.rentStartDate = data.rentStartTime
        this.form.rentEndDate = data.rentEndTime
        this.form.personRoomRelationship = data.personRoomRelationship === '' ? undefined : data.personRoomRelationship
        this.form.lesseeRelationship = data.lesseeRelationship === '' ? undefined : data.lesseeRelationship
        this.form.houseOwnerName = data.houseOwnerName
        this.form.houseOwnerCardnum = data.houseOwnerCardnum
        this.form.houseOwnerMobilenum = data.houseOwnerMobilenum
        this.form.personType = data.personType === '' ? undefined : data.personType
        this.form.groupTeam = data.groupTeam
        this.form.roomNumber = data.roomNumber
        this.form.roomFullNumber = data.roomFullNumber
        this.form.ownerState = data.ownerState
        this.form.rentWithdrawalDate = data.rentWithdrawalDate
        this.form.purchaseDate = data.purchaseDate
      }
    },
    // 项目信息切换对应房产信息改变
    changeCommunityInfo (value) {
      this.editGetassets(value)
    },
    // 根据项目匹配房产
    editGetassets (communityId) {
      const _this = this
      const type = Number(_this.queryReal.type)
      _this.roomInfo = []
      let communityAssetVS = type === 1 ? _this.ownerAssets.data.communityAssetVS : _this.ownerAssets.data
      communityAssetVS.map(data => {
        if (communityId === data.communityId) {
          _this.roomInfo = data.assets
        }
      })
      _this.roomIdOps = []
      _this.roomInfo.map(info => {
        _this.roomIdOps.push({
          text: info.houseAddress,
          value: info.houseId
        })
      })
      if (_this.roomIdOps.length > 0) {
        _this.form.roomId = _this.roomIdOps[0].value
        _this.$emit('contact', {
          method: 'getHouseId',
          data: _this.form.roomId
        })
        _this.editGetRoomInfo(_this.form.roomId)
      } else {
        _this.form.roomId = undefined
        _this.form.houseType = undefined
      }
    },
    // 更改房产信息
    changeRoomInfo (value) {
      this.editGetRoomInfo(value)
    },
    // 根据房产匹配该房产下的数据
    editGetRoomInfo (houseId) {
      const _this = this
      _this.roomInfo.map(item => {
        if (houseId === item.houseId) {
          _this.$emit('contact', {
            method: 'getHouseId',
            data: item.houseId
          })
          _this.assetId = item.id
          _this.form.houseType = item.houseType
          _this.form.userType = item.userType
          let isHouseHolder = String(item.isHouseHolder)
          this.isHouseHolder = isHouseHolder
          if (item.userRelation !== '') {
            const userRelation = Number(item.userRelation)
            _this.form.userRelation = userRelation
          }
          if (item.isPayment !== '') {
            _this.form.isPayment = item.isPayment
          }
          if (item.isMessage !== '') {
            _this.form.isMessage = item.isMessage
          }
          let isCommit = Number(item.isCommit)
          if (isCommit === 0) {
            _this.isCommit = '否'
          } else if (isCommit === 1) {
            _this.isCommit = '是'
          } else {
            _this.isCommit = '卸任'
          }
          _this.commitDuty = item.commitDuty
          if (item.isOpenDoor !== '') {
            _this.form.isOpenDoor = item.isOpenDoor
          }
          if (item.isDefault !== '') {
            _this.form.isDefault = item.isDefault
          }
          if (item.isExamine !== '') {
            _this.form.isExamine = item.isExamine
          }
          _this.form.rejectReason = item.rejectReason
          _this.isSuperManager = Number(item.isSuperManager) === 1 ? '是' : '否'
          _this.houseHolderName = item.houseHolderName ? item.houseHolderName : '暂无...'
          _this.isMemberManager = Number(item.isMemberManager) === 1 ? '是' : '否'
          _this.isOutsideRepresentative = Number(item.isOutsideRepresentative) === 1 ? '是' : '否'
          this.form.personRoomRelationship = item.personRoomRelationship === '' ? undefined : item.personRoomRelationship
          this.form.lesseeRelationship = item.lesseeRelationship === '' ? undefined : item.lesseeRelationship
          this.form.houseOwnerName = item.houseOwnerName
          this.form.houseOwnerCardnum = item.houseOwnerCardnum
          this.form.houseOwnerMobilenum = item.houseOwnerMobilenum
          this.form.personType = item.personType === '' ? undefined : item.personType
          this.form.rentStartDate = item.rentStartDate
          this.form.rentEndDate = item.rentEndDate
          this.form.groupTeam = item.groupTeam
          this.form.roomNumber = item.roomNumber
          this.form.roomFullNumber = item.roomFullNumber
          this.form.ownerState = item.ownerState
          this.form.rentWithdrawalDate = item.rentWithdrawalDate
          this.form.purchaseDate = item.purchaseDate
          this.form.isLadderControl = item.isLadderControl === 1 ? true : false
        }
      })
    },
    // 切换户主身份
    changeUserType (data) {
      if (this.userTypeOps.length > 3 && data === 1 && this.queryReal.id) {
        this.isShowHouseHolder = true
      } else {
        this.isShowHouseHolder = false
      }
    },
    submitBefore (data) {
      if (data.isExamine === 2 && !data.rejectReason) {
        alert('请输入审核原因！')
        return false
      }
      data.userId = this.userId.id
      data.assetId = this.assetId
      data.isHouseHolder = this.isHouseHolder
      data.isLadderControl = data.isLadderControl === true ? 1 : 0
      return true
    },
    submitSuccess () {
      if (!this.queryReal.id) {
        this.$alert('保存成功，点击增加房产可继续添加！')
      } else {
        this.$router.go(-1)
      }
    },
    // 新增房产
    addHouse () {
      this.$router.push({
        name: 'addHouseForm',
        query: {
          type: this.queryReal.type,
          userId: this.userId.id,
          id: this.queryReal.id
        }
      })
    },
    getHouseType (data) {
      this.form.houseType = data ? data.houseType : undefined
    },
    // 取消关联组织
    removeOrg () {
      this.$confirm('您已被管理员移出组织，请完善信息后重新提交审核', {
        title: '提示'
      }).then(confirm => {
        if (confirm) {
          let { orgId } = this.queryReal
          let params = {
            params: {
              orgId: orgId,
              userId: this.userId.id
            }
          }
          this.$axios.get(allOwnerMgrFormUrl.delStructUserInfoUrl, params).then(res => {
            if (res.status === 100) {
              this.$router.go(-1)
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.houseInfoForm-container{
  height: 100%;
  .communityId{
    display: inline-block;
    margin-bottom: 0px;
  }
  .addHouse{
    display: inline-block;
    width: 100px;
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    text-align: center;
    margin-left: 36px;
    background: #1B8CF2;
    color: white;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
  }
  .orgId{
    position: relative;
    left: 76px;
    top: 27px;
    color:#F56C6C;
  }
  .isSuperManager{
    margin:0 20px 0 120px;
  }
  .isExamine{
    display: inline-block;
    margin-right: 15px;
  }
}

::v-deep .mx-datepicker {
  width: 140px;
}
</style>
