var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: _vm.vdomId, staticClass: "OwnerMgrForm-container" }, [
    _c(
      "div",
      { staticClass: "return-wrap" },
      [
        _c("v-button", {
          attrs: { text: "返回" },
          on: { click: _vm.previous },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "leftBox" }, [
      _c("div", { staticClass: "address_info" }, [
        _vm._v("\n      " + _vm._s(_vm.houseAddress) + "\n    "),
      ]),
      _c(
        "div",
        { staticClass: "leftBox—tabs" },
        [
          _c("tabs-panel", {
            attrs: {
              tabs: _vm.tabs,
              tabRouter: false,
              before: _vm.getDetailData,
              activeLabel: _vm.activeLabel,
            },
            on: {
              "update:activeLabel": function ($event) {
                _vm.activeLabel = $event
              },
              "update:active-label": function ($event) {
                _vm.activeLabel = $event
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "rightBox" }, [
      _c("div", { staticClass: "recordFormBox" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title-left" }, [
            _vm._v("工单记录（" + _vm._s(_vm.orderRecordNum) + "）"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c("table-panel", {
              attrs: {
                headers: _vm.orderRecordHeaders,
                tableData: _vm.orderRecordTableData,
                hasOperateColumn:
                  _vm.userType !== "100" && _vm.userType !== "106",
              },
              scopedSlots: _vm._u([
                {
                  key: "operateSlot",
                  fn: function (scope) {
                    return [
                      _c("div", { staticClass: "operate" }, [
                        scope.row.status === 6 ||
                        scope.row.status === 7 ||
                        scope.row.status === 8
                          ? _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleNewWorkOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.handleNewWorkOrder(scope.row)
                                  },
                                },
                              },
                              [_vm._v("处理")]
                            ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-pagination", {
              staticClass: "pagination",
              attrs: {
                "current-page": _vm.currentRecordPage,
                "page-size": 4,
                layout: "total, prev, pager, next",
                total: _vm.orderRecordNum,
              },
              on: {
                "size-change": _vm.handleRecordSizeChange,
                "current-change": _vm.handleRecordCurrentChange,
                "update:currentPage": function ($event) {
                  _vm.currentRecordPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentRecordPage = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "recordFormBox" }, [
        _c("div", { staticClass: "title" }, [
          _c("div", { staticClass: "title-left" }, [
            _vm._v("出入记录（" + _vm._s(_vm.inOutdNum) + "）"),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "table-box" },
          [
            _c("table-panel", {
              attrs: {
                headers: _vm.inOutHeaders,
                tableData: _vm.inOutTableData,
                hasOperateColumn: false,
              },
            }),
            _c("el-pagination", {
              staticClass: "pagination",
              attrs: {
                "current-page": _vm.currentinOutPage,
                "page-size": 4,
                layout: "total, prev, pager, next",
                total: _vm.inOutdNum,
              },
              on: {
                "size-change": _vm.handleinOutSizeChange,
                "current-change": _vm.handleinOutCurrentChange,
                "update:currentPage": function ($event) {
                  _vm.currentinOutPage = $event
                },
                "update:current-page": function ($event) {
                  _vm.currentinOutPage = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }