<template>
  <div class='OwnerMgrForm-container' :key="vdomId">
    <div class="return-wrap">
      <v-button text="返回" @click="previous"></v-button>
    </div>   
    <div class="leftBox">
      <div class="address_info">
        {{houseAddress}}
      </div>
      <div class="leftBox—tabs">
        <tabs-panel :tabs="tabs" :tabRouter="false" :before="getDetailData" :activeLabel.sync="activeLabel"></tabs-panel>
      </div>
    </div>
    <div class="rightBox">
      <!-- 展示表格区域 -->
      <div class="recordFormBox">
        <div class="title">
          <div class="title-left">工单记录（{{orderRecordNum}}）</div>
        </div>
        <div class="table-box">
          <table-panel :headers="orderRecordHeaders" :tableData="orderRecordTableData" :hasOperateColumn="userType !== '100' && userType !== '106'">
            <template #operateSlot="scope">
              <div class="operate">
                <!-- {{scope.row,name}} -->
                <div @click="handleNewWorkOrder(scope.row)" v-if="scope.row.status === 6 || scope.row.status === 7 || scope.row.status === 8">查看</div>
                <div @click="handleNewWorkOrder(scope.row)" v-else>处理</div>
              </div>
            </template>
          </table-panel>
          <el-pagination
            class="pagination"
            @size-change="handleRecordSizeChange"
            @current-change="handleRecordCurrentChange"
            :current-page.sync="currentRecordPage"
            :page-size="4"
            layout="total, prev, pager, next"
            :total="orderRecordNum">
          </el-pagination>
        </div>
      </div>

      <div class="recordFormBox">
        <div class="title">
          <div class="title-left">出入记录（{{inOutdNum}}）</div>
        </div>
        <div class="table-box">
          <table-panel :headers="inOutHeaders" :tableData="inOutTableData" :hasOperateColumn="false">
          </table-panel>
          <el-pagination
            class="pagination"
            @size-change="handleinOutSizeChange"
            @current-change="handleinOutCurrentChange"
            :current-page.sync="currentinOutPage"
            :page-size="4"
            layout="total, prev, pager, next"
            :total="inOutdNum">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { TabsPanel, TablePanel } from 'components/bussiness'
import BaseInfoForm from './newBaseInfoForm'
import HouseInfoForm from './newHouseInfoForm'
import CarInfoForm from './CarInfoForm'
import ParkingLotInfoForm from './newParkingLotInfoForm'
import StoreroomInfoForm from './newStoreroomInfoForm'
import LogInfoForm from './LogInfoForm'
import * as allOwnerMgrFormUrl from './api'
import { Pagination} from 'element-ui'
import { orderStatusMap, inOutWayMap, inOutuserSourceMap } from './map'
export default {
  name: 'newOwnerMgrForm',
  components: {
    TabsPanel,
    TablePanel,
    elPagination:Pagination,
  },
  props:{
    editQuery:Object
  },
  data () {
    return {
      activeName: 'baseInfoForm',
      owner: {
        data: ''
      },
      partyMemberInfo: {
        data: undefined
      },
      ownerAssets: {
        data: ''
      },
      cars: {
        data: ''
      },
      parkingLot: {
        data: ''
      },
      storeroom: {
        data: ''
      },
      ownerWorkInfoV: {
        data: ''
      },
      ownerPersonalInfoV: {
        data: ''
      },
      userId: {
        id: ''
      },
      houseId: {
        id: ''
      },
      tabs: [],
      activeLabel: '基础信息',
      communityIdOps: [],
      vdomId: 1,
      query:{},
      houseAddress: null, // 地址信息

      // 工单记录所需变量字段
      orderRecordNum: null, // 服务工单总数
      orderRecordHeaders: [{
        prop: 'categoryName',
        label: '工单类型'
      }, {
        prop: 'createTime',
        label: '提单时间'
      }, {
        prop: 'description',
        label: '工单内容'
      },{
        prop: 'status',
        label: '处理状态',
        formatter (row) {
          return orderStatusMap[row.status]
        }
      }], // 工单记录表单表头
      orderRecordTableData: [], // 数据
      currentRecordPage: 1, // 当前页

      // 出入记录变量字段
      inOutdNum: null, // 出入记录总数
      inOutHeaders: [{
        prop: 'passTime',
        label: '出入时间'
      }, {
        prop: 'openSource',
        label: '出入方式',
        formatter (row) {
          return inOutWayMap[row.openSource]
        }
      }, {
        prop: 'doorName',
        label: '出入地点'
      },{
        prop: 'userSource',
        label: '数据来源',
        formatter (row) {
          return inOutuserSourceMap[row.userSource]
        }
      }], // 出入记录表单表头
      inOutTableData: [], // 数据
      currentinOutPage: 1, // 当前页
      userType: null, // 登录用户类型
    }
  },
  computed: {
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
  },
  created () {
    this.query = this.subPageType == 'drawer' ? this.editQuery : this.$route.query
    this.userType =this.$store.state.userInfo.userType;
    let type = Number(this.query.type)
    if(!this.subPageType == 'drawer'){
      if (this.query.id && type === 1) {
        this.$setBreadcrumb('修改注册用户信息')
      } else if (this.query.id && type === 0) {
        this.$setBreadcrumb('修改未注册用户信息')
      } else {
        this.$setBreadcrumb('新增未注册用户信息')
      }
    }
    this.tabs = [
      {
        label: '基础信息',
        component: BaseInfoForm,
        props: {
          query:this.query,
          ownerInfo: this.owner,
          partyMemberInfo: this.partyMemberInfo
        }
      }, {
        label: '房产信息',
        component: HouseInfoForm,
        props: {
          ownerAssets: this.ownerAssets,
          userId: this.userId,
          query:this.query,
          isAudio: this.query.id && type === 1
        }
      }, {
        label: '车辆信息',
        component: CarInfoForm,
        props: {
          cars: this.cars,
          query:this.query,
          userId: this.userId,
          houseId: this.houseId
        }
      }, {
        label: '车位信息',
        component: ParkingLotInfoForm,
        props: {
          query:this.query,
          parkingLot: this.parkingLot,
          userId: this.userId,
          houseId: this.houseId
        }
      },
      {
        label: '库房信息',
        component: StoreroomInfoForm,
        props: {
          query:this.query,
          storeroom: this.storeroom,
          userId: this.userId,
          houseId: this.houseId
        }
      }
    ]

    let logTab = {
      label: '操作日志',
      component: LogInfoForm,
      props: {
        query:this.query,
        storeroom: this.storeroom,
        userId: this.userId,
        houseId: this.houseId
      }
    }

    if(this.query.id && type === 1) {
      this.tabs.push(logTab)
    }

    // 获取记录表格方法
    this.getOwnerPassageLogList();

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === 'ownerCarForm') {
        vm.activeLabel = '车辆信息'
      } else if (from.name === 'carPlaceForm') {
        vm.activeLabel = '车位信息'
      } else if (from.name === 'storeRoomForm') {
        vm.activeLabel = '库房信息'
      } else if (from.name === 'addHouseForm') {
        vm.activeLabel = '房产信息'
      }
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.vdomId++ // 让页面在相同路由情况下重新加载
    next()
  },
  methods: {
    previous () {
      this.$router.go(-1)
    },
    setActiveLabel (activeLabel) {
      this.activeLabel = activeLabel
    },
    getDetailData (next) {
      let type = Number(this.query.type)
      let { addUserId, id, orgId, communityId } = this.query
      if (id || addUserId) {
        let url = type === 1 ? allOwnerMgrFormUrl.getRegisterDetailUrl : allOwnerMgrFormUrl.getUnRegisterDetailUrl
        this.$axios.get(url, {
          params: {
            id: id || addUserId,
            orgId: orgId,
            communityId: communityId
          }
        })
          .then(res => {
            let returnStatus = String(res.status)
            if (returnStatus === '100') {
              this.ownerAssets.data = type === 1 ? res.data.ownerAssets : res.data.communityAssetVS
              console.log('++++this.ownerAssets.data++++', this.ownerAssets.data.communityAssetVS[0].assets[0].houseAddress)
              this.houseAddress = this.ownerAssets.data.communityAssetVS[0].assets[0].houseAddress;
              this.owner.data = res.data.owner

              console.log('res.data.owner+++++', res.data.owner)
              if(res.data.owner.mobileNum) {
                this.getWorkOrderList();
              }
              this.partyMemberInfo.data = res.data.partyMemberInfo
              this.cars.data = res.data.cars
              this.parkingLot.data = res.data.parkingLot
              this.storeroom.data = res.data.storeroom
              this.ownerPersonalInfoV.data = res.data.ownerPersonalInfoV
              this.ownerWorkInfoV.data = res.data.ownerWorkInfoV
              console.log(this.ownerWorkInfoV,'jjjkkggg')
              this.getCommunityId()
              this.getUserId(res.data.owner.id)
              next()
            }
          })
      } else {
        next()
      }
    },
    getCommunityId () {
      let type = Number(this.query.type)
      let communityAssetVS = type === 1 ? this.ownerAssets.data.communityAssetVS : this.ownerAssets.data
      communityAssetVS.map(item => {
        this.communityIdOps.push({
          text: item.communityName,
          value: item.communityId
        })
      })
      let communityIds = this.communityIdOps.length > 0 ? this.communityIdOps[0].value : ''
      let communityId = this.query.communityId ? this.query.communityId : communityIds
      communityAssetVS.map(item => {
        if (item.communityId === communityId) {
          let houseId = item.assets.length > 0 && item.assets[0].houseId
          this.getHouseId(houseId)
        }
      })
    },
    getUserId (userId) {
      this.userId.id = userId
    },
    getHouseId (houseId) {
      this.houseId.id = houseId
    },
    // 获取工单记录方法
    getWorkOrderList() {
      let _this = this;
      this.$axios.get( allOwnerMgrFormUrl.getWorkOrderListURL, {
        params: {
          type: 2,
          executeSearch: 1,
          curPage: this.currentRecordPage,
          pageSize: 4,
          userMobile: this.owner.data.mobileNum,
        }
      }).then(res => {
        if(res.status === 100) {
          let { maxRow, resultList}  = res.data;
          _this.orderRecordNum = maxRow;
          _this.orderRecordTableData = resultList;
        } else {
          _this.orderRecordNum = 0;
          _this.orderRecordTableData = [];
        }
      })
    },
    // 工单记录直接查询某一页
    handleRecordSizeChange(e) {
      this.currentRecordPage = e;
      this.getWorkOrderList();
    },
    // 工单记录 上一页下一页查询
    handleRecordCurrentChange(e) {
      this.currentRecordPage = e;
      this.getWorkOrderList();
    },

    // 获取出入记录方法
    getOwnerPassageLogList() {
      let _this = this;
      let { addUserId, id } = this.query
      this.$axios.get( allOwnerMgrFormUrl.getOwnerPassageLog, {
        params: {
          userId: id || addUserId,
          curPage: this.currentinOutPage,
          pageSize: 4,
        }
      }).then(res => {
        if(res.status === 100) {
          let { maxRow, resultList}  = res.data;
          _this.inOutdNum = maxRow;
          _this.inOutTableData = resultList;
        } else {
          _this.inOutdNum = 0;
          _this.inOutTableData = [];
        }
      })
    },
    // 出入记录直接查询某一页
    handleinOutSizeChange(e) {
      this.currentinOutPage = e;
      this.getOwnerPassageLogList();
    },
    // 出入记录 上一页下一页查询
    handleinOutCurrentChange(e) {
      this.currentinOutPage = e;
      this.getOwnerPassageLogList();
    },
    // 服务工单跳转到工单详情方法 - 测试
    handleNewWorkOrder(row){
        const menuInfo = this.$store.getters['menuStore/getMenuInfo'](947241)
        this.$router.push({
          name:'iframePanel',
          query:{
            pid:1,
            cid:3400,
            fid:94751,
            sid:947241,
            src: menuInfo.item.url,
            orderId:row.id,
            isReplace: 1
          }
        })
      }
  }
}
</script>

<style scoped lang="scss">
.OwnerMgrForm-container {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  position: relative;
  .return-wrap{
    position: absolute;
    right: 20px;
    top: -50px;

  }

  .leftBox {
    width: 50%;
    height: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding-bottom: 20px;

    .address_info {
      // width: 100%;
      display: flex;
      align-items: center;
      padding: 20px 25px;
      text-align: left;
      background: #fff;
      border: 1px solid #DCDFE6;
      border-radius: 10px;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
      margin-bottom: 15px;
      font-size: 24px;
      font-weight: bold;
    }

    .leftBox—tabs {
      padding-top: 10px;
      flex: 1;
      overflow: auto;
      border: 1px solid #DCDFE6;
      border-radius: 10px;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
      // height: 70%;
    }
  }

  .rightBox {
    margin-left: 20px;
    flex: 1;
    box-sizing: border-box;

    .recordFormBox {
      margin-top: 15px;
      border-radius: 10px;
      box-shadow: 10px 10px 10px rgba(0, 0, 0, .2);
      display: flex;
      flex-direction: column;
      border: 1px solid #DCDFE6;

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;

        &-left {
          font-size: 26px;
          font-weight: bold;
          color: #000;
        }
      }

      .line {
        height: 1px;
        background: #DCDFE6;
        width: 70%;
      }

      .table-box {
        flex: 1;
        padding: 10px;
      }
    }

    .recordFormBox:first-child {
      margin-top: 0px;
    }
  }

  .operate {
    color: blue;

    div {
      cursor: pointer;
    }
  }
}
</style>
