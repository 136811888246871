var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "storeroomInfoForm-container" },
    [
      _c("form-panel", { attrs: { hasHeader: false } }, [
        _c(
          "ul",
          { staticClass: "storeroomInfoForm" },
          [
            _vm._l(_vm.storeroom.data, function (storeroomData, index) {
              return _c("li", { key: index }, [
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("用户房号："),
                    ]),
                    _c("span", [_vm._v(_vm._s(storeroomData.userAddress))]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", { staticStyle: { width: "100%" } }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("库房地址："),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(storeroomData.communityName) +
                          "--" +
                          _vm._s(storeroomData.storeRoomAddress)
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("库房编号："),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(storeroomData.storageRoomNumber)),
                    ]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("完整编号："),
                    ]),
                    _c("span", [
                      _vm._v(_vm._s(storeroomData.storageRoomFullNumber)),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("办理时间："),
                    ]),
                    _c("span", [_vm._v(_vm._s(storeroomData.handleTime))]),
                  ]),
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("到期时间："),
                    ]),
                    _c("span", [_vm._v(_vm._s(storeroomData.expireTime))]),
                  ]),
                ]),
                _c("div", { staticClass: "carInfo" }, [
                  _c("div", [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("库房状态："),
                    ]),
                    _c("span", [_vm._v(_vm._s(storeroomData.status))]),
                  ]),
                ]),
                _vm.isShowUpdate
                  ? _c(
                      "div",
                      {
                        staticClass: "editCars",
                        on: {
                          click: function ($event) {
                            return _vm.editCars(storeroomData.id)
                          },
                        },
                      },
                      [_vm._v("编辑该库房")]
                    )
                  : _vm._e(),
              ])
            }),
            _vm.isShowAddStore
              ? _c(
                  "div",
                  { staticClass: "createCars", on: { click: _vm.createCars } },
                  [_vm._v("登记库房信息")]
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }