<template>
  <div class='parkingLotInfoForm-container'>
    <form-panel :hasHeader="false">
      <ul class="parkingLotInfoForm">
        <li v-for="(datas, index) in parkingLotData" :key="index">
          <div class="carInfo">
            <div style="width: 100%">
              <span class="title">用户房号：</span>
              <span>{{datas.houseName}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div style="width: 100%">
              <span class="title">车位地址：</span>
              <span>{{datas.carHouseName}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div>
              <span class="title">车位编号：</span>
              <span>{{datas.carNumber}}</span>
            </div>
            <div>
              <span class="title">完整编号：</span>
              <span>{{datas.carFullNumber}}</span>
            </div>
          </div>
          <div class="carInfo">
            <div>
              <span class="title">车位状态：</span>
              <span>{{datas.statusText}}</span>
            </div>
            <div>
              <span class="title">车位证明状态：</span>
              <span>{{datas.certificateStatusText}}</span>
            </div>
          </div>
         <div class="carInfo">
            <div>
              <span class="title">车位证明：</span>
              <v-preview v-if="datas.annexUrl.length > 0" :imgs="datas.annexUrl" :textMode="false" buttonText="查看车位证明照片"></v-preview>
            </div>
            <div>
              <span class="title">共享车位：</span>
              <span>{{datas.sharedStatusText}}</span>
            </div>
          </div>
          <div class="editPark" v-if="isShowUpdate" @click="editPark(datas.id)">编辑该车位</div>
        </li>
         <div class="createPark" v-if="isShowAddParkPlace" @click="createPark">登记车位信息</div>
      </ul>
    </form-panel>
  </div>
</template>
<script>
import vPreview from 'components/control/v-preview/v-preview'
import { parkingCertificateStatus, parkingSharedStatus, parkingStatus } from './map'
export default {
  name: 'newParkingLotInfoForm',
  components: {
    vPreview
  },
  props: ['parkingLot', 'userId', 'houseId','query'],
  data () {
    return {
      isShowUpdate: false,
      isShowAddParkPlace: false,
      parkingLotData: []
    }
  },
  computed:{
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
  },
  created () {
    const update = Number(this.$store.state.permission.update)
    const addParkPlace = Number(this.$store.state.permission.addParkPlace)
    this.isShowUpdate = update === 1
    this.isShowAddParkPlace = addParkPlace === 1
    let { id, addUserId } = this.queryReal
    if ((id || addUserId) && this.parkingLot.data.length > 0) {
      this.controlPermission()
    }
  },
  methods: {
    controlPermission () {
      this.parkingLot.data.map(item => {
        item.statusText = parkingStatus[item.status]
        item.sharedStatusText = parkingSharedStatus[item.sharedStatus]
        item.certificateStatusText = parkingCertificateStatus[item.certificateStatus]
        item.annexUrl = []
        item.parkingLotAnnexUrl.map(data => {
          item.annexUrl.push({
            src: data
          })
        })
      })
      this.parkingLotData = this.parkingLot.data
    },
    editPark (id) {
      this.$router.push({
        name: 'carPlaceForm',
        query: {
          id: id
        }
      })
    },
    createPark () {
      this.$router.push({
        name: 'carPlaceForm',
        query: {
          userId: this.userId.id,
          houseId: this.houseId.id
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.parkingLotInfoForm-container {
  height: 100%;
}
.parkingLotInfoForm{
  width:100%;
  .ownerHouseId{
      margin-left: 50px;
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 10px;
      }
    }
  .carInfo{
    div{
      display: inline-block;
      margin: 15px 0px 15px 0px;
      word-wrap: break-word;
      .title{
        font-size: 14px;
        color: #606266;
        margin-right: 5px;
      }
    }
    div:nth-child(2n-1){
      width: 50%;
      margin-left: 48px;
    }
  }
  .editPark{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 5px 0 18px 64px;
    cursor: pointer;
  }
  .createPark{
    border: 1px solid #1B8CF2;
    border-radius: 4px;
    background: #1B8CF2;
    color: white;
    text-align: center;
    line-height: 32px;
    width: 150px;
    height: 32px;
    margin: 60px 0 15px 50px;
    cursor: pointer;
  }
}
</style>
