<template>
  <div class='baseInfoForm-container'>
    <form-panel v-bind="submitConfig" :form="form" :hasHeader="false" :submitContentType="submitContentType" :submitBefore="submitBefore" :submitSuccess="submitSuccess" submitText="保存">
      <template v-if="String(type) === '0'">
        <el-row type="flex">
          <el-form-item  label="姓名" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]" prop="userName">
            <v-input v-model="form.userName"></v-input>
          </el-form-item>
          <el-form-item label="手机号" :rules="mobileNumChangeRules" prop="mobileNum">
            <!-- <v-input v-if="isAdd" v-model="form.mobileNum" :maxlength="11"></v-input>
            <v-input v-if="!isAdd" disabled v-model="form.mobileNum" :maxlength="11"></v-input> -->
            <!-- 当000开头,表示为自动生成的手机号码 -->
            <v-input :disabled="disabled" v-model="form.mobileNum" :maxlength="11"></v-input>
            <span v-show="editShowResetPassword" class="resetPassword" @click="resetPassword">重置密码</span>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="性别" :rules="[{ required: true, message: '请选择性别', trigger: 'change' }]" prop="sex">
            <v-select v-model="form.sex" :options="sexOps"></v-select>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <v-datepicker type="date" v-model="form.birthday"/>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="称谓" prop="alias">
            <v-input v-model="form.alias"></v-input>
          </el-form-item>
          <el-form-item label="电话" prop="phoneNum" :rules="[{ validator: validatePhone, trigger: 'blur' }]">
            <v-input v-model="form.phoneNum"></v-input>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="职业" prop="career">
            <v-input v-model="form.career"></v-input>
          </el-form-item>
          <el-form-item label="政治面貌" prop="politicsStatus">
            <v-select v-model="form.politicsStatus" :options="politicsStatusOps"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="民族" prop="nation">
            <v-select v-model="form.nation" filterable :options="nationOps"></v-select>
          </el-form-item>
          <el-form-item label="籍贯" prop="nativePlace">
            <v-select v-model="form.nativePlace" :options="nativePlaceOps"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="婚姻状况" prop="isMarry">
            <v-select v-model="form.isMarry" :options="isMarryOps"></v-select>
          </el-form-item>
          <el-form-item label="证件类型" prop="cardType">
            <v-select v-model="form.cardType" :options="cardTypeOps"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="证件号码" prop="cardNum" :rules="[{ validator: validateCardNum, trigger: 'blur' }]">
            <v-input v-model="form.cardNum"></v-input>
          </el-form-item>
          <el-form-item label="QQ号码" prop="qqNum">
            <v-input v-model="form.qqNum"></v-input>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="邮件地址"  :rules="emailAddressChangeRules" prop="emailAddress">
            <v-input v-model="form.emailAddress"></v-input>
          </el-form-item>
          <el-form-item label="是否实名认证" prop="realnameExamine">
            <v-select v-model="form.realnameExamine" :options="realnameExamineList"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="数据来源" prop="userSource">
            <v-select v-model="form.userSource" :options="userSourceOps"></v-select>
          </el-form-item>
          <el-form-item label="联系电话" prop="phoneNum">
            <v-input v-model="form.phoneNum"></v-input>
          </el-form-item>
        </el-row>
        <el-form-item label="个人介绍" prop="userIntro">
          <v-textarea v-model="form.userIntro" width='400' :maxlength="200"></v-textarea>
        </el-form-item>
        <el-form-item v-if="showCardImgs" label="证件照片" prop="cardImgs">
          <v-preview :imgs="cardImgs" :textMode="false" buttonText="查看证件照片"></v-preview>
        </el-form-item>
         <el-form-item :span="12" v-if="showSubImgs" label="人脸照片" prop="subImgs">
          <v-preview :imgs="subImgs" :textMode="false" buttonText="查看证件照片"></v-preview>
        </el-form-item>
      </template>
      <template v-if="String(type) === '1'">
        <el-row type="flex">
          <el-form-item  label="姓名" :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]" prop="userName">
            <v-input v-model="form.userName" :disabled="isRealName"></v-input>
          </el-form-item>
          <el-form-item label="手机号" :rules="mobileNumChangeRules" prop="mobileNum">
            <v-input :disabled="disabled" v-model="form.mobileNum" :maxlength="11"></v-input>
            <span v-show="editShowResetPassword" class="resetPassword" @click="resetPassword">重置密码</span>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="性别" :rules="[{ required: true, message: '请选择性别', trigger: 'change' }]" prop="sex">
            <v-select v-model="form.sex" :options="sexOps" :disabled="isRealName"></v-select>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <v-datepicker type="date" v-model="form.birthday" :disabled="isRealName"/>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="称谓" prop="alias">
            <v-input v-model="form.alias"></v-input>
          </el-form-item>
          <el-form-item label="电话" prop="phoneNum" :rules="[{ validator: validatePhone, trigger: 'blur' }]">
            <v-input v-model="form.phoneNum"></v-input>
          </el-form-item>
        </el-row>
        <el-row type="flex">

        </el-row>
        <el-row type="flex">
          <el-form-item label="职业" prop="career">
            <v-input v-model="form.career"></v-input>
          </el-form-item>
          <el-form-item label="政治面貌" prop="politicsStatus">
            <v-select v-model="form.politicsStatus" :options="politicsStatusOps"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="民族" prop="nation">
            <v-select v-model="form.nation" filterable :options="nationOps"></v-select>
          </el-form-item>
          <el-form-item label="籍贯" prop="nativePlace">
            <v-select v-model="form.nativePlace" :options="nativePlaceOps"></v-select>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="QQ号码" prop="qqNum">
            <v-input v-model="form.qqNum"></v-input>
          </el-form-item>
          <el-form-item label="邮件地址"  :rules="emailAddressChangeRules" prop="emailAddress">
            <v-input v-model="form.emailAddress"></v-input>
          </el-form-item>
        </el-row>
        <el-row type="flex">
          <el-form-item label="婚姻状况" prop="isMarry">
            <v-select v-model="form.isMarry" :options="isMarryOps"></v-select>
          </el-form-item>
          <el-form-item label="证件类型" prop="cardType">
            <v-select v-model="form.cardType" :options="cardTypeOps" :disabled="isRealName"></v-select>
          </el-form-item>
        </el-row>
        <el-form-item label="是否实名认证" prop="realnameExamine">
          {{realnameExamine[form.realnameExamine]}}
        </el-form-item>
        <el-form-item label="数据来源" prop="userSource">
          {{userSourceMap[form.userSource]}}
        </el-form-item>
        <el-form-item v-if="showCardImgs" label="证件照片" prop="cardImgs">
          <v-preview :imgs="cardImgs" :textMode="false" buttonText="查看证件照片"></v-preview>
        </el-form-item>
        <el-form-item label="证件号码" prop="cardNum" :rules="[{ validator: validateCardNum, trigger: 'blur' }]">
          <v-input v-model="form.cardNum" :disabled="isRealName" :width="200"></v-input>
        </el-form-item>
        <el-form-item label="个人介绍" prop="userIntro">
          <v-textarea v-model="form.userIntro" :maxlength="200"></v-textarea>
        </el-form-item>
      </template>
      <template v-if="partyMemberInfo.data">
        <col2-detail>
          <col2-block title="党员资料">
            <col2-item label="入党时间" :text="partyMemberInfo.data.partyTime"></col2-item>
            <col2-item label="党内职务" :text="partyMemberInfo.data.partyDutyStr"></col2-item>
            <col2-item :span="24" label="党员类型" :text="partyMemberInfo.data.memberTypeStr"></col2-item>
            <col2-item :span="24" label="特殊党员类型" :text="partyMemberInfo.data.specialMemberTypeStr"></col2-item>
          </col2-block>
        </col2-detail>
      </template>
      <el-form-item label="用户标签" prop="labelId">
        <chosenListPanel :list.sync="labelId" @select="choseRelationInfo"></chosenListPanel>
        <multiSelect
        :isShow.sync="isShowMultiSelect"
        :searchUrl="multiSelectUrl"
        :headers="multiSelectHeader"
        :backFill.sync="labelId"
        :searchParams="searchelseParams"
        :responseParams="responseParams"
        :responseKey="responseKey"
        :handleData="formatter"
        :appendToBody="true"
        @callback="multiSelectcallback">
          <template #searchSlot>
            <v-input v-model="searchelseParams.labelName" label="标签名称"></v-input>
            <v-select v-model="searchelseParams.labelType" label="一级分类" :options="labelTypeOps" @change="getCategoryId"></v-select>
            <v-select v-model="searchelseParams.categoryId" label="二级分类" :options="categoryIdOps"></v-select>
            <v-input v-model="searchelseParams.inuser" label="创建人"></v-input>
            <v-select v-model="searchelseParams.status" label="状态" :options="statusOps"></v-select>
            <v-datepicker type="rangedatetimer" :startTime.sync="searchelseParams.createStartTime" :endTime.sync="searchelseParams.createEndTime" label="创建时间"/>
            <v-datepicker type="rangedatetimer" :startTime.sync="searchelseParams.joinStartTime" :endTime.sync="searchelseParams.joinEndTime" label="最近用户加入时间"/>
          </template>
        </multiSelect>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import * as allOwnerMgrFormUrl from './api'
import vPreview from 'components/control/v-preview/v-preview'
import { ChosenListPanel, MultiSelect, Col2Block, Col2Detail, Col2Item } from 'components/bussiness'
import { Row} from 'element-ui'
import nativePlaceData from '@/../public/static/json/province.json'
import * as commonData from './map'
import { mobileRegular, telephoneRegular } from '@/common/regular'
import { generateMapByOpts } from '@/common/utils'
export default {
  name: 'BaseInfoForm',
  components: {
    MultiSelect,
    vPreview,
    ChosenListPanel,
    Col2Block,
    Col2Detail,
    Col2Item,
    elRow: Row,
  },
  props: ['ownerInfo', 'partyMemberInfo','query'],
  data () {
    const validateMobile = (rule, value, callback) => {
      // let regExp = mobileRegular
      let regExp = /^\d{11}$/
      if (!regExp.test(value)) {
        callback(new Error('手机号码应为11位的数字！'))
      } else {
        callback()
      }
    }
    const validateEmailAddress = (rule, value, callback) => {
      let regExp = /^([A-Z0-9a-z._%+-]+@([A-Za-z0-9-]+.)+[A-Za-z]{2,4})$/g
      if (value && !regExp.test(value)) {
        callback(new Error('不符合邮箱格式，请重新填写!'))
      } else {
        callback()
      }
    }
    return {
      isAdd: false,
      disabled: false,
      form: {
        userName: '',
        mobileNum: '',
        sex: undefined,
        birthday: '',
        // nickName: '',
        phoneNum: '',
        career: '',
        politicsStatus: undefined,
        nation: undefined,
        nativePlace: undefined,
        isMarry: undefined,
        cardType: undefined,
        cardNum: '',
        qqNum: '',
        emailAddress: '',
        alias: '',
        // realnameExamine: '',
        realnameExamine: undefined,
        userSource: undefined,
        cardImgs: [],
        subImgs: [],
        userIntro: ''
      },
      realnameExamineList: [
        { text: '已认证', value: 1 },
        { text: '未认证', value: 0 }
      ],
      houseId: '',
      userId: '',
      newUserId: '',
      cardImgs: [],
      subImgs: [],
      editShowResetPassword: false,
      showCardImgs: false,
      showSubImgs: false,
      submitConfig: {
        submitUrl: allOwnerMgrFormUrl.createSubmitUrl,
        submitContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        submitMethod: 'POST'
      },
      realnameExamineText: '待认证',
      labelId: [],
      nationOps: [{
        text: "请选择",
        value: undefined
      }],
      nativePlaceOps: [],
      mobileNumChangeRules: [
        { required: true, message: '请输入11位手机号码！', trigger: 'blur' },
        { validator: validateMobile, trigger: 'blur' }
      ],
      emailAddressChangeRules: [
        { validator: validateEmailAddress, trigger: 'blur' }
      ],
      isShowMultiSelect: false,
      sexOps: commonData.sexOps,
      sexMap: commonData._sex,
      politicsStatusOps: commonData.politicsStatusOps,
      isMarryOps: commonData.isMarryOps,
      isMarryMap: commonData.isMarryMap,
      realnameExamine: commonData._realnameExamine,
      cardTypeOps: [],
      cardTypeMap: {},
      userSourceOps: commonData.userSourceOps.filter(item => item.value !== 1),
      userSourceMap: commonData.userSourceMap,
      multiSelectUrl: allOwnerMgrFormUrl.multiSelectUrl,
      responseParams: {
        id: 'id',
        name: 'labelName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      labelTypeOps: commonData.labelTypeOps,
      statusOps: commonData.statusOps,
      categoryIdOps: [],
      multiSelectHeader: [{
        prop: 'labelName',
        label: '标签名称'
      }, {
        prop: 'category',
        label: '分类'
      }, {
        prop: 'status',
        label: '状态'
      }, {
        prop: 'inuser',
        label: '创建人'
      }, {
        prop: 'intime',
        label: '创建时间'
      }, {
        prop: 'latestJoinTime',
        label: '最新用户加入时间'
      }],
      searchelseParams: {
        labelName: '',
        labelType: undefined,
        categoryId: undefined,
        inuser: '',
        status: undefined,
        createStartTime: '',
        createEndTime: '',
        joinStartTime: '',
        joinEndTime: ''
      },
      type: '',

    }
  },
  computed: {
    resetPasswordAuth () {
      return this.$store.state.permission.resetPassword === 1
    },
    subPageType() {
      return this.$store.state.theme['sub-page-type'] || 'inner'
    },
    queryReal(){
      return  this.subPageType == 'drawer' ? this.query : this.$route.query
    },
    submitContentType () {
      // if (this.isAdd) {
      //   return 'application/x-www-form-urlencoded;charset=UTF-8'
      // } else {
      //   return 'application/json;charset=UTF-8'
      // }
      return 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    // 判断是否实名
    isRealName () {
      return Number(this.form.realnameExamine) === 1 ? true : false
    }
  },
  created () {
    this.getSelectData()
    this.init()
    if (this.queryReal.id) {
      this.form.userId = ''
      this.submitConfig.submitUrl = allOwnerMgrFormUrl.updateSubmitUrl
      this.submitConfig.submitMethod = 'PUT'
    }
    if (this.queryReal.isAdd) {
      this.isAdd = true
    }
  },
  mounted () {
    console.log(this.queryReal)
    let { id, addUserId, type } = this.queryReal
    if (id || addUserId) {
      this.echoData()
    }
    this.type = type
  },
  methods: {
    validatePhone (rule, value, callback) {
      let regExp = mobileRegular
      let connectionRegExp = telephoneRegular
      let showError = false
      if (value && value.length > 0) {
        if (!regExp.test(value) && !connectionRegExp.test(value)) {
          showError = true
        }
      }
      if (showError) {
        callback(new Error('手机/电话号码格式不正确'))
      } else {
        callback()
      }
    },
    validateCardNum (rule, value, callback) {
      //身份证
      let regExp1 = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      //军官证
      let regExp2 = /^[\u4E00-\u9FA5](字第)([0-9a-zA-Z]{4,8})(号?)$/
      //护照
      let regExp3 = /^([a-zA-z]|[0-9]){9}$/
      //驾驶证
      let regExp5 = /^\d{12}$/
      //港澳通行证
      let regExp6 = /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/
      //台胞证
      let regExp7 = /^\d{8}$|^[a-zA-Z0-9]{10}$|^\d{18}$/
      //户口本
      let regExp8 = /^\d{9}$/
      //警官证
      let regExp9 = /^[0-9]{8,9}$/
      let showError = false
      if (value && value.length > 0) {
        if(this.form.cardType == 1) {
          if (!regExp1.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 2) {
          if (!regExp2.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 3) {
          if (!regExp3.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 5) {
          if (!regExp5.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 6) {
          if (!regExp6.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 7) {
          if (!regExp7.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 8) {
          if (!regExp8.test(value)) {
            showError = true
          }
        } else if(this.form.cardType == 9) {
          if (!regExp9.test(value)) {
            showError = true
          }
        }
      }
      if (showError) {
        callback(new Error('证件号码格式不正确'))
      } else {
        callback()
      }
    },
    init () {
      this.handleData()
      this.getNativePlaceData()
    },
    // 区分注册与未注册用户的数据处理
    handleData () {
      const type = Number(this.queryReal.type)
      if (type === 0) {
        this.form.userSource = 4
      }
    },
    // 重置密码
    async resetPassword () {
      let isOk = await this.$confirm('是否确认重置密码!')
      isOk && this.resetPw()
    },
    resetPw () {
      let id = this.userId
      this.$axios({
        method: 'put',
        url: allOwnerMgrFormUrl.resetPasswordUrl,
        data: this.$qs.stringify({ userId: id })
      }).then((res) => {
        if (res.status === 100) {
          this.$alert(res.data)
        }
      })
      // this.$axios.post(allOwnerMgrFormUrl.resetPasswordUrl, { 'userId': id }).then((res) => {
      //   let returnStatus = String(res.status)
      //   if (returnStatus === '100') {
      //     this.$alert(res.msg)
      //   }
      // })
    },
    // 获取籍贯
    getNativePlaceData () {
      if (nativePlaceData) {
        this.nativePlaceOps = [
          {
            text: '请选择',
            value: undefined
          }
        ]
      }
      nativePlaceData.name.map(item => {
        this.nativePlaceOps.push({
          text: item,
          value: item
        })
      })
    },
    // 获取民族
    async getSelectData () {
      let _this = this
      let res = await this.$axios.get(allOwnerMgrFormUrl.getInitCodeWordList)
      if (res.status === 100) {
        res.data.cardTypeVoList.forEach(item => {
          _this.cardTypeOps.push({
            text: item.value,
            value: item.code
          })
        })
        _this.cardTypeMap = generateMapByOpts(_this.cardTypeOps)
        res.data.nationList.forEach(function (item) {
          _this.nationOps.push({
            text: item,
            value: item
          })
        })
      }
    },
    // 选择multiSelect弹框数据之后的回调函数
    multiSelectcallback (items) {
      let _this = this
      _this.labelId = items
    },
    choseRelationInfo () {
      this.isShowMultiSelect = true
    },
    formatter (item) {
      item.status = commonData._status[item.status]
    },
    getCategoryId (value) {
      this.$axios.get(allOwnerMgrFormUrl.getCategoryIdUrl, {
        params: {
          parentId: value
        }
      })
        .then(res => {
          let returnStatus = String(res.status)
          if (returnStatus === '100') {
            this.categoryIdOps = [{
              text: '全部',
              value: null
            }]
            let _this = this
            res.data.forEach(function (item) {
              _this.categoryIdOps.push({
                text: item.categoryName,
                value: item.id
              })
            })
          }
        })
    },
    submitBefore (data) {
      data.labelIds = JSON.stringify(this.labelId.map(item => item.id))
      data.reviewReason = 2
      if (!this.isAdd) {
        data.houseId = this.houseId
        data.communityId = this.queryReal.communityId
      }
      return true
    },
    submitSuccess (data) {
      if (data) {
        this.userId = data
        this.$emit('contact', {
          method: 'getUserId',
          data: data
        })
        this.$message.success('保存成功')
        // let path = this.$router.history.current.path
        // let type = this.$route.query.type
        // this.$router.push({ path, query: { addUserId: data, type: type } })
        // this.$emit('getUserId', data)
      }
      // 当基础信息保存成功后tabs为houseInfoForm
      this.$emit('contact', {
        method: 'setActiveLabel',
        data: '房产信息'
      })
      return false
    },
    // 编辑数据回显
    echoData () {
      let data = this.ownerInfo.data
      if (data) {
        this.userId = data.id
        // this.$emit('getUserId', this.userId)
        this.$emit('contact', {
          method: 'getUserId',
          data: this.userId
        })
        const viewPicture = Number(this.$store.state.permission.viewPicture)
        if (data.cardImgs && data.cardImgs.length > 0 && viewPicture === 1) {
          this.showCardImgs = true
          data.cardImgs.map(item => {
            this.cardImgs.push({
              src: item
            })
          })
        }
        if (data.subImgs && data.subImgs.length > 0 && viewPicture === 1) {
          this.showSubImgs = true
          data.subImgs.map(item => {
            this.subImgs.push({
              src: item
            })
          })
        }
        for (let key in this.form) {
          if (key === 'isMarry') {
            // 单独处理婚姻状况
            if (data[key] === 99) { 
              // isMarry: 99 表示未选择婚姻状况
              this.form[key] = undefined
            } else {
              this.form[key] = data[key]
            }
          } else {
            this.form[key] = data[key] ? data[key] : undefined
          }
        }
        this.form.userId = data.id
        if (!this.form.realnameExamine) {
          this.form.realnameExamine = 0
        }
        this.editShowResetPassword = data.userSource === 1
        this.realnameExamineText = data.realnameExamine === 0 ? '待认证' : '已认证'
        this.form.userIntro = data ? data.userIntro : ''
        this.labelId = data.labels
        this.houseId = data.houseId
        this.form.cardType = data.cardType + ''
        if (data.cardType == '') { this.form.cardType = undefined }
        this.form.userSource = Number(data.userSource)
        this.disabled = (!this.isAdd) && (/^(1)\d{10}$/.test(this.form.mobileNum))
      }
    }
  }
}
</script>
<style scoped lang="scss">
.baseInfoForm-container {
  height: 100%;
}
.resetPassword{
  margin-left: 20px;
  color: #23527c;
  cursor: pointer;
}
.table-div {
  display: flex;
  align-items: center;
}
::v-deep .chosen-list-panel {
  width: 400px;
}

::v-deep .footer {
  width: 550px;
}
</style>
